import { ArticlePreview } from 'interfaces/content/articles/Articles';
import { SectionVariant } from 'interfaces/Section';
import { FontFamily, FontSizes, ResponsiveStyling } from 'styles/theme/types';
import { filterObjectKeys } from 'utils/objectUtils';

/**
 * Possible variants of data to be displayed in an ArticleCard component
 *
 * E.g.:
 * - `hero` - data displayed on standalone hero articles
 * - `hero-${SectionVariant}` - data displayed on a hero article card from a specific section variant
 */
export type ArticleCardFieldsVariant =
  | 'hero'
  | 'hub'
  | 'hub-microsite'
  | 'product-home-carousel'
  | 'product-home-featured-secondary'
  | 'home-featured-primary'
  | 'home-featured-secondary'
  | `hero-${SectionVariant}`
  | `list-${SectionVariant}`
  | `list-secondary-${SectionVariant}`;

/**
 * Extracts the necessary fields from an article object based on the specified fields variant
 *
 * @param {ArticlePreview} article - The article object containing all fields
 * @param {ArticleCardFieldsVariant} variant - The variant of the article card that determines which fields to extract
 * @returns {Object} The object containing only the extracted fields for the given variant
 */
export function getArticleCardFields(article: ArticlePreview, variant?: ArticleCardFieldsVariant): ArticlePreview {
  try {
    let fields: (keyof ArticlePreview)[] = [];
    const eyebrowFields = ['eyebrowSectionLabel', 'eyebrowSectionLink'] as (keyof ArticlePreview)[];
    const datePublishedFields = ['dateGMTFormatted', 'dateFormatted'] as (keyof ArticlePreview)[];
    const dateUpdatedFields = ['dateUpdatedGMTFormatted', 'dateUpdatedFormatted'] as (keyof ArticlePreview)[];

    switch (true) {
      // ARTICLE SECTION - CARD LIST VARIATIONS
      case variant === `list-${SectionVariant.TopPicks}`:
        fields = ['title', 'titleLink', 'authors', ...eyebrowFields, ...dateUpdatedFields];
        break;
      case variant === `list-${SectionVariant.HomeLatestArticles}`:
        fields = ['title', 'titleLink', 'authors'];
        break;
      case variant === `list-${SectionVariant.Section}`:
        fields = ['title', 'titleLink', 'image', 'excerpt', 'authors', ...datePublishedFields];
        break;
      case variant === `list-${SectionVariant.SubSection}`:
      case variant === `list-${SectionVariant.SectionLatestArticles}`:
      case variant === `list-${SectionVariant.LatestArticles}`:
      case variant === `list-${SectionVariant.MoreArticles}`:
        fields = ['title', 'titleLink', 'image', 'authors', ...datePublishedFields];
        break;
      case variant === `list-${SectionVariant.TopArticles}`:
        fields = ['title', 'titleLink', 'excerpt', 'authors', ...dateUpdatedFields];
        break;
      case variant === `list-${SectionVariant.TopArticles2}`:
        fields = ['title', 'titleLink', 'image', 'authors', ...dateUpdatedFields];
        break;

      // ARTICLE SECTION - HERO CARD VARIATIONS
      case variant === `hero-${SectionVariant.Section}`:
        fields = ['title', 'titleLink', 'image', 'authors'];
        break;
      case variant === `hero-${SectionVariant.SectionLatestArticles}`:
        fields = ['title', 'titleLink', 'image', 'excerpt', 'authors', ...dateUpdatedFields];
        break;
      case variant === `hero-${SectionVariant.TopArticles}`:
        fields = ['title', 'titleLink', 'image', 'authors', ...dateUpdatedFields];
        break;
      case variant?.startsWith('hero'):
        fields = ['title', 'titleLink', 'image', 'authors', ...eyebrowFields, ...datePublishedFields];
        break;

      // ARTICLE SECTION - SECONDARY CARD LIST VARIATIONS
      case variant?.startsWith('list-secondary'):
        fields = ['title', 'titleLink', 'image', 'excerpt', 'authors'];
        break;

      // HUB PAGE ARTICLE CARD VARIATIONS
      case variant === 'hub':
        fields = ['title', 'titleLink', 'image', 'authors', ...eyebrowFields, ...datePublishedFields];
        break;
      case variant === 'hub-microsite':
        fields = ['title', 'titleLink', 'image', 'authors', ...datePublishedFields];
        break;

      // HOMEPAGE ARTICLE CARD VARIATIONS
      case variant === 'home-featured-primary':
      case variant === 'home-featured-secondary':
        fields = ['title', 'titleLink', 'image', 'authors', ...eyebrowFields, ...datePublishedFields];
        break;

      // PRODUCT HOMEPAGE ARTICLE CARD VARIATIONS
      case variant === 'product-home-carousel':
      case variant === 'product-home-featured-secondary':
        fields = ['title', 'titleLink', 'image', 'authors', ...eyebrowFields, ...datePublishedFields];
        break;

      // DEFAULT (ALL DATA)
      default:
        fields = ['title', 'titleLink', 'image', 'excerpt', 'authors', ...eyebrowFields, ...datePublishedFields];
        break;
    }
    return filterObjectKeys(article, fields) as ArticlePreview;
  } catch (error) {
    return article;
  }
}

export type ArticleCardLayoutVariant = 'hero' | 'card-xs' | 'card-sm' | 'card-md' | 'card-lg' | 'card-xl';

export type CustomFontSizes = Omit<FontSizes, 'xlarge' | 'xxlarge'>;

export function predefinedTitleSize(cardVariant: ArticleCardLayoutVariant): ResponsiveStyling<keyof CustomFontSizes> {
  switch (cardVariant) {
    case 'hero':
      return { default: 'level4', mdMin: 'level2' };
    case 'card-xs':
      return { default: 'level6', mdMin: 'level4' };
    case 'card-sm':
      return { default: 'level5', mdMin: 'level5' };
    case 'card-md':
      return { default: 'level6', mdMin: 'level6' };
    case 'card-lg':
      return { default: 'level6', mdMin: 'level4' };
    case 'card-xl':
      return { default: 'level4', mdMin: 'level3' };
    default:
      return { default: 'level2', mdMin: 'level2' };
  }
}

export function predefinedTitleFontFamily(
  cardVariant: ArticleCardLayoutVariant,
  changesDirection: boolean,
): FontFamily {
  switch (cardVariant) {
    case 'hero':
    case 'card-xl':
    case 'card-lg':
      return 'saol';
    case 'card-md':
      return changesDirection ? 'graphikCond' : 'saol';
    case 'card-sm':
    case 'card-xs':
      return 'graphikCond';
    default:
      return 'graphikCond';
  }
}
